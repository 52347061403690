import React from 'react';
import { getIdString } from '../../utils';
import { TocTrackedH3 } from '../toc-tracked-heading/toc-tracked-heading';

import styles from './article-subsection.module.scss';
import WYSIWYG from '../wysiwyg/wysiwyg';

const ArticleSubsection = ({ title, parent, body }) => (
  <div id={getIdString([parent, title])} className={styles.wrapper}>
    <TocTrackedH3 id={[parent, title]} modifier={styles.title}>
      {title}
    </TocTrackedH3>
    {/* We tell the parser the subsection parent so that it knows to expand that when
    navigating to footnotes from the Notes section */}
    <WYSIWYG body={body} context={[parent, title]} />
  </div>
);

export default ArticleSubsection;
