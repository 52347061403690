import React from 'react';
import ArticleSectionCollapsible from '../article-section-collapsible';
import ArticleSubSection from '../article-subsection';

const Appendices = ({ appendices }) => {
  const appendicesKeys = Object.keys(appendices);

  return (
    <ArticleSectionCollapsible
      title={`Appendices`}
      countDisplay={appendicesKeys.length}
    >
      {appendicesKeys.map((delta) => (
        <ArticleSubSection
          title={`Appendix : ${appendices[delta].title}`}
          parent={`appendices`}
          body={appendices[delta].body.processed}
          key={`appendix--${delta}`}
        />
      ))}
    </ArticleSectionCollapsible>
  );
};

export default Appendices;
