import React from 'react';
import { groupItemsByTerm } from '../../utils';
import ArticleSectionCollapsible from '../article-section-collapsible';
import ArticleSectionNonCollapsible from '../article-section-non-collapsible';
import { TocTrackedH3 } from '../toc-tracked-heading/toc-tracked-heading';

import styles from './key-design-decisions.module.scss';
import WYSIWYG from '../wysiwyg/wysiwyg';

const KeyDesignDecisions = ({ kdds }) => {
  const kddsByTerm = groupItemsByTerm(kdds, 'keyTerm', (item) => ({
    body: item.body.processed,
    title: item.title
  }));

  const kddsKeys = Object.keys(kddsByTerm);
  if (kddsKeys.length === 0) {
    return null;
  }

  return (
    <ArticleSectionNonCollapsible title={`Key Design Decisions`}>
      {kddsKeys.map((kddTermName) => (
        <div key={kddTermName} className={styles.set}>
          <TocTrackedH3
            id={['Key Design Decisions', kddTermName]}
            countDisplay={kddsByTerm[kddTermName].length}
          >
            {kddTermName}
          </TocTrackedH3>
          <ul className={styles.list}>
            {kddsByTerm[kddTermName].map((kdd, delta) => (
              <li key={`${kddTermName}--${delta}`}>
                <ArticleSectionCollapsible
                  headingElement={'h4'}
                  title={kdd.title}
                  id={[
                    `Key Design Decisions`,
                    kddTermName,
                    `decision-${delta}`
                  ]}
                  modifier={'collapsible-deep-nested'}
                >
                  <WYSIWYG
                    body={kdd.body}
                    context={[
                      'Key Design Decisions',
                      kddTermName,
                      `decision-${delta}`
                    ]}
                  />
                </ArticleSectionCollapsible>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </ArticleSectionNonCollapsible>
  );
};

export default KeyDesignDecisions;
