import React from 'react';
import { graphql } from 'gatsby';
import { getArticleCategory } from '../utils';

import PageLayout from '../components/page-layout/page-layout';
import SEO from '../components/seo';
import ArticleLayout from '../components/article-layout';
import ArticleTitleCard from '../components/article-title-card';
import ContentBlockComponent from '../components/content-block';
import KeyDesignDecisions from '../components/key-design-decisions';
import ArticleSection from '../components/article-section';
import ArticleSectionCollapsibleBasic from '../components/article-section-collapsible-basic';
import CustomContentSections from '../components/custom-content-sections';
import { TocTrackedH2 } from '../components/toc-tracked-heading';
import References from '../components/references';
import Appendices from '../components/appendices';
import ArticleTaxonomy from '../components/article-taxonomy';

const Survey = ({ data }) => {
  const survey = data.nodeSurvey;
  const title = survey.title;

  return (
    <PageLayout>
      <SEO title={title} />
      <ArticleLayout
        articleId={survey.articleId}
        articleFileLink={survey.articleFileLink}
      >
        {/* Title section */}
        <ArticleTitleCard title={title} eyebrow={getArticleCategory(survey)} />

        {/* Introductory Note */}
        {survey?.introduction?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Introductory Note`}
            body={survey?.introduction?.processed}
          />
        ) : (
          <ContentBlockComponent
            machine_name={'introductory_note'}
            modifier={'article'}
          />
        )}

        {/* Abstract */}
        {survey?.abstract?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Abstract`}
            body={survey?.abstract?.processed}
          />
        ) : (
          ''
        )}

        {/* Overview */}
        {survey?.overview?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Overview`}
            body={survey?.overview?.processed}
          />
        ) : (
          ''
        )}

        {/* Custom sections */}
        {survey?.relationships?.customContent ? (
          <CustomContentSections
            sections={survey?.relationships?.customContent}
          />
        ) : (
          ''
        )}

        {/* Framing Themes */}
        {survey?.framingThemes?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Framing Themes for Key Design Features`}
            body={survey?.framingThemes?.processed}
          />
        ) : (
          ''
        )}

        {/* Key Design Decisions */}
        {survey?.relationships?.keyDesignDecisions ? (
          <KeyDesignDecisions
            kdds={survey?.relationships?.keyDesignDecisions}
          />
        ) : (
          ''
        )}

        {/* Evaluation */}

        {survey?.evaluation?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Evaluation`}
            body={survey?.evaluation?.processed}
          />
        ) : (
          ''
        )}

        {/* References */}
        {survey?.references && survey?.references.length > 0 ? (
          <References references={survey?.references} />
        ) : (
          ''
        )}

        {/* Appendices */}
        {/* If no appendices, hide display */}
        {survey?.relationships?.appendices &&
        survey?.relationships?.appendices.length > 0 ? (
          <Appendices appendices={survey?.relationships?.appendices} />
        ) : (
          ''
        )}

        {/* Footnotes */}

        {/* Taxonomy */}
        {survey?.relationships?.interventionType ||
        survey?.relationships?.institutions ||
        survey?.relationships?.countriesAndRegions ||
        survey?.relationships?.crises ||
        survey?.relationships?.issues ? (
          <ArticleSection id={['article-taxonomy']}>
            <TocTrackedH2 id={['article-taxonomy']}>Taxonomy</TocTrackedH2>
            <ArticleTaxonomy
              intervention_type={survey?.relationships?.interventionType}
              institutions={survey?.relationships?.institutions}
              countriesAndRegions={survey?.relationships?.countriesAndRegions}
              crises={survey?.relationships?.crises}
              issues={survey?.relationships?.issues}
            />
          </ArticleSection>
        ) : (
          ''
        )}
      </ArticleLayout>
    </PageLayout>
  );
};

export const query = graphql`
  query SurveyNodeQuery($slug: String!) {
    nodeSurvey(path: { alias: { eq: $slug } }) {
      title
      articleId: id
      articleFileLink: field_link_to_pdf
      relationships {
        author: field_author {
          name: field_name
          bio: field_bio
        }
        interventionType: field_intervention_type {
          name
        }
        institutions: field_institutions {
          name
        }
        countriesAndRegions: field_countries_and_regions {
          name
        }
        crises: field_crises {
          name
        }
        issues: field_issues {
          name
        }
        keyDesignDecisions: field_key_design_decisions {
          ...kddSurveyQuery
        }
        customContent: field_custom_content {
          title: field_title
          body: field_body {
            processed
          }
        }
        appendices: field_section_appendices {
          title: field_title
          body: field_body {
            processed
          }
        }
      }
      abstract: field_abstract {
        processed
      }
      overview: field_overview {
        processed
      }
      framingThemes: field_framing_themes {
        processed
      }
      evaluation: field_evaluation {
        processed
      }
      references: field_references {
        title
        url: uri
      }
      introduction: field_introduction {
        processed
      }
    }
  }
`;

export default Survey;
